import { makeStyles } from '@material-ui/core/styles';
import { StyleProps } from './DesktopTable';

export const useStyles = makeStyles(
  (theme: any) => ({
    root: {
      fontFamily: theme?.typography?.fontFamily,
      '& *': {
        boxSizing: 'border-box',
      },
    },
    tableContainerRoot: {
      '&::-webkit-scrollbar': {
        height: '8px',
        width: '8px',
        marginTop: '0.5px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'white',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme?.palette?.secondary?.shade_400,
        borderRadius: '12px',
      },
    },
    table: {
      borderCollapse: 'separate',
    },
    tableRow: {
      height: '54px',
      background: theme?.palette?.basic?.white,
      '&:nth-of-type(odd) > td': {
        background: ({ variant }: StyleProps) =>
          variant === 'striped' ? theme?.palette?.secondary?.shade_50 : '',
      },
      '&:nth-of-type(even) > td': {
        background: ({ variant }: StyleProps) =>
          variant === 'striped' ? theme?.palette?.basic?.white : '',
      },
      '& td': {
        borderBottom: ({ variant }: StyleProps) =>
          variant === 'striped'
            ? 'none'
            : `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      },
    },
    hover: {
      '&$hover:hover > td': {
        background: ({ variant }: StyleProps) =>
          variant === 'striped'
            ? theme?.palette?.primary?.shade_50
            : theme?.palette?.secondary?.shade_50,
      },
    },
    selected: {
      '& td': {
        background: `${theme?.palette?.primary?.shade_50} !important`,
      },
    },
    cell: {
      boxSizing: 'border-box',
      padding: '0 24px',
      fontSize: '14px',
      color: 'black',
      border: 'none',
      background: ({ variant }: StyleProps) =>
        variant === 'striped' ? 'none' : 'inherit',
    },
    checkboxCell: {
      boxSizing: 'border-box',
      // width: ({ fixedColumns }: StyleProps) =>
      //   fixedColumns.left || fixedColumns.right ? '0%' : '16px',
      padding: 0,
      paddingLeft: '16px',
    },
    checkboxBorder: {
      borderTop: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    checkbox: {
      padding: 0,
    },
    avatar: {
      height: '32px',
      width: '32px',
      marginRight: '16px',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    avatarContainer: {
      display: 'flex !important',
      alignItems: 'center',
    },
    avatarCell: {
      boxSizing: 'border-box',
      paddingLeft: '16px',
    },
    border: {
      border: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    shadowRight: {
      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    shadowLeft: {
      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    activeSortColumn: {
      background: `${theme?.palette?.secondary?.shade_50} !important`,
    },
    editableCell: {
      padding: '8px 8.68px',
    },
    editable: {
      width: '100%',
      boxSizing: 'border-box',
      display: 'block',
      '&:hover': {
        borderRadius: '5px',
        border: `1.5px solid ${theme?.palette?.secondary?.shade_200}`,
      },
      border: '1.5px solid transparent',
    },
    hideCell: {
      display: 'none !important',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          border: `1.5px solid ${theme?.palette?.primary?.main}`,
        },
      },
    },
    textFieldInput: {
      fontSize: '14px',
      padding: '8px 8.68px',
    },
    textFieldFocused: {
      backgroundColor: theme?.palette?.basic?.white,
    },
    outline: {
      border: `1.5px solid ${theme?.palette?.secondary?.shade_200}`,
    },
    specialOutline: {
      border: `1.5px solid ${theme?.palette?.secondary?.shade_200}`,
      borderRadius: '5px',
      '& .Mui-focused': {
        border: `16px solid ${theme?.palette?.secondary?.shade_200}`,
      },
    },
    paginationClassName: {
      float: 'right',
      marginTop: '8px',
    },
    moreIconButtonCell: {
      borderRight: 'none',
      borderLeft: 'none',
      padding: '0 !important',
    },
    moreIconButton: {
      padding: 0,
      color: theme?.palette?.primary?.main,
    },
    seeMoreBtn: {
      height: '17px',
      background: 'inherit',
      textDecoration: 'underline',
      padding: '0',
      '&:hover': {
        background: 'inherit',
      },
      color: theme?.palette?.primary?.main,
      '& svg': {
        color: theme?.palette?.primary?.main,
      },
    },
    moreActionPopper: {
      borderRadius: '5px',
      display: 'flex',
      padding: '16px',
      gap: '14px',
      flexDirection: 'column',
      '& span': {
        cursor: 'pointer',
      },
    },
    stickyRight: {
      position: 'sticky',
      right: 0,
    },
  }),
  { name: 'ComponentLibrary', index: 2 }
);
