import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MuiRadioGroup from '@material-ui/core/RadioGroup';

import { makeStyles } from '@material-ui/core/styles';
import { FC, useEffect, useState } from 'react';
import { Radio } from '../../atoms';
import { RadioProps } from '../../atoms/Radio/Radio';
import classnames from 'classnames';
import withGenerateClassName from '../../../themes/withGenerateClassName';

const useStyles = makeStyles(
  (globaltheme) => ({
    title: {
      fontWeight: 500,
      fontSize: globaltheme.spacing(5),
      color: globaltheme?.palette?.secondary?.main,
      marginBottom: '12.06px',
    },
    label: {
      gap: '8.01px',
      marginLeft: 0,
      marginBottom: '14.59px',
      '& span': {
        fontWeight: 400,
        fontSize: '14px',
        color: globaltheme?.palette?.secondary?.main,
      },
    },
  }),
  { name: 'ComponentLibrary' }
);

interface Option {
  label: string;
  value: Object | string;
  error?: boolean;
  disabled?:boolean;
}

interface RadioGroupProps {
  title: string;
  options: Option[];
  selected?: Option;
  onChange?: Function;
  radioProps?: RadioProps;
  isRow?: boolean;
  formControlClassName?: string;
  formLabelClassName?: string;
  radioGroupClassName?: string;
  formControlLabelClassName?: string;
  radioClassName?: string;
}

const RadioGroup: FC<RadioGroupProps> = ({
  title,
  options,
  selected: defaultValue,
  onChange,
  radioProps,
  isRow,
  formControlClassName,
  formLabelClassName,
  radioGroupClassName,
  formControlLabelClassName,
  radioClassName,
  ...restProps
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    defaultValue && setSelected(defaultValue);
  }, [defaultValue]);

  const getOption = (value: string) =>
    options.find(
      (option: Option) => option.value.toString() === value.toString()
    );

  const handleChange = (event: any) => {
    setSelected(getOption(event.target.value));
    onChange && onChange(getOption(event.target.value));
  };

  return (
    <FormControl
      component="fieldset"
      className={formControlClassName}
      {...restProps}
    >
      {title && (
        <FormLabel
          component="legend"
          className={classnames(classes.title, formLabelClassName)}
        >
          {title}
        </FormLabel>
      )}
      <MuiRadioGroup
        className={radioGroupClassName}
        row={isRow}
        name={title}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <FormControlLabel
            className={classnames(classes.label, formControlLabelClassName)}
            key={index}
            value={option.value}
            control={
              <Radio
                radioClassName={radioClassName}
                checked={selected?.value === option.value}
                error={option.error}
                disabled={option.disabled}
                {...radioProps}
              />
            }
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default withGenerateClassName(RadioGroup);
