import { makeStyles } from '@material-ui/core/styles';

interface IStyle {
  backgroundVariant: 'light' | 'dark';
  borderedFields?: boolean;
  additionalAction?: boolean;
  fieldsLeftPadded?: boolean;
  selected?: boolean;
}

const useStyles = makeStyles(
  (theme: any) => ({
    container: {
      backgroundColor: ({ backgroundVariant, selected }: IStyle) => {
        if (selected) return theme?.palette?.primary?.shade_50;
        return backgroundVariant === 'light'
          ? theme?.palette?.basic?.white
          : theme?.palette?.primary?.shade_50;
      },
      border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      borderRadius: '5px',
      userSelect: 'none',
    },
    importantFields: {
      '& > $importantField + $importantField': {
        borderTop: ({ borderedFields }: IStyle) =>
          borderedFields
            ? `1.5px solid ${theme?.palette?.secondary?.shade_100}`
            : '',
      },
    },
    importantField: {
      padding: ({ borderedFields }: IStyle) =>
        borderedFields ? '12px' : '6px 12px',
      paddingLeft: ({ fieldsLeftPadded }: IStyle) =>
        fieldsLeftPadded ? '0' : '',
      marginLeft: ({ fieldsLeftPadded }: IStyle) =>
        fieldsLeftPadded ? '12px' : '',
      '&:first-child': {
        paddingTop: '12px',
      },
      '&:last-child': {
        paddingBottom: '12px',
      },
      color: theme?.palette?.secondary?.main,
      position: 'relative',
      fontSize: '16px',
      lineHeight: '20px',
      overflowWrap: 'anywhere',
    },
    spaceBetweenAlignment: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '12px',
      '& .value': {
        textAlign: 'right',
      },
    },
    editIconBtn: {
      padding: '0',
      marginLeft: '4px',
      '& svg': {
        color: theme?.palette?.secondary?.shade_600,
        width: '18px',
        height: '18px',
      },
    },
    importantFieldLabel: {
      fontWeight: 600,
      marginRight: '4px',
      flexShrink: 0,
    },
    moreActionIconButton: {
      position: 'absolute',
      right: '8px',
      padding: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      '&:hover': {
        background: 'inherit',
      },
      '& svg': {
        color: theme?.palette?.primary?.main,
      },
    },
    primarySection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px',
      position: 'relative',
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    primaryKeyLeftSection: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    withMoreActions: {
      paddingRight: '36px',
    },
    primaryWrapper: {},
    primaryKey: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 600,
      // TODO: color not in palette
      color: 'rgba(0, 0, 0, 0.85)',
    },
    secondaryKey: {
      fontSize: '14px',
      lineHeight: '17px',
      marginTop: '4px',
      // TODO: color not in palette
      color: 'rgba(0, 0, 0, 0.85)',
    },
    footer: {
      display: 'flex',
      justifyContent: ({ additionalAction }: IStyle) =>
        additionalAction ? 'space-between' : 'flex-end',
      alignItems: 'center',
      padding: '12px 3.7px 12px 12px',
      background: 'inherit',
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      position: 'relative',
      minHeight: '41px',
      boxSizing: 'border-box',
    },
    secondary__footer: {
      background: theme?.palette?.primary?.shade_100,
      borderTop: 'none',
      marginLeft: '-1.5px',
      marginBottom: '-1.5px',
      marginRight: '-1.5px',
      borderRadius: '0 0 5px 5px',
    },
    footerLeftSection: {
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
    },
    additionalActionLabel: {
      // TODO: color not in palette
      color: 'rgba(0, 0, 0, 0.6)',
    },
    additionalActionBtn: {
      height: '17px',
      background: 'inherit',
      padding: '0',
      '&:hover': {
        background: 'inherit',
        textDecoration: 'none',
      },
    },
    seeMoreBtn: {
      height: '17px',
      background: 'inherit',
      textDecoration: 'underline',
      padding: '0',
      '&:hover': {
        background: 'inherit',
      },
      color: theme?.palette?.primary?.main,
      '& svg': {
        color: theme?.palette?.primary?.main,
      },
    },
    seeMoreButtonLabel: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    moreActionsPaper: {
      boxShadow: '0px 4px 4px rgba(14, 13, 12, 0.16)',
      borderRadius: '5px',
      padding: '12px 11.35px',
      width: '122px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      alignItems: 'flex-start',
    },
    moreActionsBtn: {
      padding: '0',
      height: '17px',
      minWidth: '0',
      color: theme?.palette?.secondary?.shade_600,
      fontSize: '16px',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    selectedAvatar: {
      backgroundColor: theme?.palette?.primary?.main,
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
