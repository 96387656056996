import { makeStyles } from '@material-ui/core/styles';

interface IStyleProps {
  isDark: boolean;
}

export default makeStyles((theme) => ({
  record_wrapper: {
    marginBottom: '16px',
    background: ({ isDark }: IStyleProps) =>
      isDark
        ? theme?.palette?.secondary?.shade_50
        : theme?.palette?.basic?.white,
    border: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    borderRadius: '5px',
    '& > $content_row + $content_row': {
      borderTop: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    },
  },
  record_header: {
    height: '57px',
    boxSizing: 'border-box',
    padding: '12px',
    borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  content_row: {
    height: '40px',
    marginLeft: '12px',
    paddingRight: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  record_footer: {
    height: '42px',
    padding: '12px',
    boxSizing: 'border-box',
    borderTop: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}), { name: 'ComponentLibrary' });
