import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    container: {
      width: '100%',
      fontFamily: theme?.typography?.fontFamily,
    },
    mainContent: {
      boxSizing: 'border-box',
      backgroundColor: theme?.palette?.basic?.white,
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme?.palette?.basic?.white,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme?.palette?.secondary?.shade_400,
        borderRadius: '8px',
      },
    },
    footer: {
      height: '52px',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      padding: '0 16px',
      color: theme?.palette?.secondary?.main,
      backgroundColor: theme?.palette?.basic?.white,
    },
    moreActionDrawerWrapper: {
      padding: '9px 0 17px 11.56px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    moreActionDrawerItem: {
      fontSize: '16px',
      lineHeight: '19px',
      padding: '7px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  { name: 'ComponentLibrary' }
);
