import { FC } from 'react';
import Dropdown, { IDropdown } from '../../atoms/Dropdown/Dropdown';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface ComplexDropdownProps extends IDropdown {}

const ComplexDropdown: FC<ComplexDropdownProps> = (props) => {
  const { checked } = props;
  return <Dropdown multiple={checked} {...props} />;
};

export default withGenerateClassName(ComplexDropdown);
