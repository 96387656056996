import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './Modal.styles';
import classnames from 'classnames';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { Button } from '../../atoms';
import withGenerateClassName from '../../../themes/withGenerateClassName';

interface ModalProps extends Omit<DialogProps, 'title'> {
  title: React.ReactNode;
  showCross?: boolean;
  customHeader?: React.ReactNode;
  customFooter?: React.ReactNode;
  backDropClickClose?: boolean;
  escapeKeyDownClose?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  onPrimaryBtnClick?: () => void;
  onSecondaryBtnClick?: () => void;
  showSecondaryBtn?: boolean;
  paddedForMobile?: boolean;
  primaryBtnFullWidth?: boolean;
  footerBtnsPosition: 'space-between' | 'flex-start' | 'flex-end' | 'center';
  modalRootClassName?: string;
  modalPaperClassName?: string;
  backDropClassName?: string;
  secondaryBtnClassName?: string;
  primaryBtnClassName?: string;
  dialogContentClassName?: string;
  dialogActionClassName?: string;
  dialogTitleClassName?: string;
  footerClassName?: string;
  primaryBtnProps?: any;
  secondaryBtnProps?: any;
  dialogContentProps?: any;
  dialogActionsProps?: any;
  dialogTitleProps?: any;
  enableContentOverflow?: boolean;
  onClickCross?: Function;
}

const Modal: FC<ModalProps> = ({
  children,
  title,
  showCross = true,
  customHeader,
  customFooter,
  onClose,
  onClickCross,
  backDropClickClose,
  escapeKeyDownClose,
  primaryBtnText,
  secondaryBtnText,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  showSecondaryBtn = true,
  paddedForMobile,
  primaryBtnFullWidth,
  footerBtnsPosition = 'flex-end',
  modalRootClassName,
  modalPaperClassName,
  backDropClassName,
  secondaryBtnClassName,
  primaryBtnClassName,
  dialogContentClassName,
  dialogActionClassName,
  dialogTitleClassName,
  footerClassName,
  primaryBtnProps = {},
  secondaryBtnProps = {},
  dialogContentProps = {},
  dialogActionsProps = {},
  dialogTitleProps = {},
  enableContentOverflow = false,
  ...restProps
}) => {
  const classes = useStyles({
    paddedForMobile,
    footerBtnsPosition,
    enableContentOverflow,
  });

  const handleClose = (
    event: React.ChangeEvent<HTMLInputElement>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (!onClose) return;
    if (reason && !backDropClickClose && reason === 'backdropClick') return;
    if (reason && !escapeKeyDownClose && reason === 'escapeKeyDown') return;
    onClose(event, reason);
  };

  return (
    <Dialog
      classes={{
        root: [classes.dialogRoot, modalRootClassName].join(' '),
        paper: [classes.dialogPaper, modalPaperClassName].join(' '),
      }}
      BackdropProps={{
        classes: { root: classnames(classes.backDrop, backDropClassName) },
      }}
      onClose={handleClose}
      {...restProps}
    >
      <DialogTitle
        classes={{
          root: classnames(classes.dialogTitleRoot, dialogTitleClassName),
        }}
        {...dialogTitleProps}
      >
        {customHeader || (
          <Box className={classes.dialogHeader}>
            {title}
            {showCross && (
              <IconButton
                disableRipple
                disableFocusRipples
                component="span"
                className={classes.dialogCrossIconBtn}
                onClick={onClickCross || onClose}
              >
                <CloseRoundedIcon
                  viewBox="3 4 19.06 17.01"
                  className={classes.dialogCrossIcon}
                />
              </IconButton>
            )}
          </Box>
        )}
      </DialogTitle>

      <DialogContent
        classes={{
          root: classnames(classes.dialogContentRoot, dialogContentClassName),
        }}
        {...dialogContentProps}
      >
        {children}
      </DialogContent>

      <DialogActions
        classes={{
          root: classnames(classes.dialogActionsRoot, dialogActionClassName),
        }}
        {...dialogActionsProps}
      >
        {customFooter || (
          <Box className={classnames(classes.dialogFooter, footerClassName)}>
            {showSecondaryBtn && (
              <Button
                onClick={onSecondaryBtnClick}
                color="subtleSecondary"
                variant="text"
                size="medium"
                className={classnames(
                  classes.footerBtn,
                  classes.secondaryBtn,
                  secondaryBtnClassName
                )}
                {...secondaryBtnProps}
              >
                {secondaryBtnText}
              </Button>
            )}
            <Button
              fullWidth={primaryBtnFullWidth}
              onClick={onPrimaryBtnClick}
              size="medium"
              color="primary"
              className={classnames(classes.footerBtn, primaryBtnClassName)}
              {...primaryBtnProps}
            >
              {primaryBtnText}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withGenerateClassName(Modal);
