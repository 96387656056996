import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    table_wrapper: {
      boxShadow: '0px 3px 0px rgba(0, 0, 0, 0.14)',
      borderRadius: '8px',
      background: theme?.palette?.basic?.white,
      overflow: 'hidden',
      paddingBottom: '17px',
    },
    table: {
      background: theme?.palette?.basic?.white,
    },
    table_head: {
      background: theme?.palette?.secondary?.shade_50,
      height: '50px',
      boxSizing: 'border-box',
      padding: '14px 16px 12px 16px',
      borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    table_body: {
      '& > $table_row + $table_row': {
        borderTop: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      },
    },
    first_column_content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    table_row: {
      height: '54px',
    },
    table_cell: {
      padding: '0 16px',
      borderBottom: 'none',
    },
  }),
  { name: 'ComponentLibrary', index: 2 }
);
