import { makeStyles } from '@material-ui/core/styles';

interface IStyles {
  fullScreen?: boolean;
  showToolbar?: boolean;
}

const useStyles = makeStyles(
  (theme: any) => ({
    mobileRoot: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'scroll',
      zIndex: 200,
      background: theme?.palette?.basic?.white,
    },
    placeholderWrapper: {
      border: `1px solid ${theme?.palette?.secondary?.shade_200}`,
      paddingBottom: '12px',
      background: theme?.palette?.basic?.white,
      borderRadius: '5px',
    },
    showcaseContainer: {
      background: theme?.palette?.basic?.white,
      borderRadius: '5px',
      minHeight: '64px',
      padding: '12px 12px 0 12px',
      wordBreak: 'break-word',
      '& p': {
        marginBlockStart: '0px',
        marginBlockEnd: '0px',
      },
    },
    mobileBox: {
      maxHeight: '169px',
      //TODO: Check why this is needed, this cause width issue on mobile when in fullscreen
      // whiteSpace: 'nowrap',
      overflowY: 'scroll',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
    },
    desktopBox: {
      maxHeight: '148px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme?.palette?.basic?.white,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme?.palette?.secondary?.shade_400,
        borderRadius: '10px',
      },
    },
    placeholder: {
      fontSize: '16px',
      color: theme?.palette?.secondary?.shade_300,
    },
    fullScreenHeader: {
      height: '60.8px',
      boxSizing: 'border-box',
      borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
      background: theme?.palette?.basic?.white,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: '16.55px',
      paddingLeft: '16.21px',
      position: ({ fullScreen }) => (fullScreen ? 'sticky' : 'static'),
      top: 0,
      zIndex: 1,
    },
    headerLeftSection: {
      display: 'flex',
      gap: '11.69px',
    },
    backArrow: {
      padding: '0',
      '& svg': {
        color: theme?.palette?.secondary?.main,
      },
    },
    modalTitle: {
      color: theme?.palette?.secondary?.main,
      fontSize: '18px',
      fontWeight: 700,
    },
    mobileModalPaper: {
      borderRadius: 0,
    },
    mobileRootWrapper: {
      background: theme?.palette?.basic?.white,
      height: ({ showToolbar }) => (showToolbar ? '74vh' : '67vh'),
    },
    mobileSaveBtn: {
      paddingLeft: '20.79px',
      paddingRight: '20.66px',
    },
    customToolbar: {
      position: ({ fullScreen }) => (fullScreen ? 'fixed' : 'sticky'),
      zIndex: 200,
      top: ({ fullScreen }) => (fullScreen ? '60.8px' : 0),
      left: 0,
      right: 0,
    },
    editable: {
      padding: ({ fullScreen, showToolbar }) =>
        fullScreen
          ? !showToolbar
            ? '12px 16px 0px !important'
            : '129.5px 16px 0px !important'
          : '16px 16px 0px',
    },
    desktopModalPaper: {
      minWidth: '500px',
    },
    editor: ({ fullScreen }: IStyles) =>
      fullScreen
        ? {
            paddingLeft: '20.42px',
            paddingRight: '16.55px',
          }
        : {
            paddingLeft: '24px',
            paddingRight: '26.49px',
          },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
