import React, { useState, useCallback } from 'react';
import classnames from 'classnames';

import MobileTableRecord, {
  IPrimaryAction,
  IActionsDrawerConfig,
} from './components/MobileTableRecord/MobileTableRecord';
import MobileTableDrawer, {
  IColumn,
  IActions,
  IDrawerConfig,
} from './components/MobileTableDrawer/MobileTableDrawer';
import MobilePagination from '../../atoms/MobilePagination/MobilePagination';
import MobilePopover from '../../atoms/MobilePopover/MobilePopover';
import MobileTableLoader from './components/MobileTableLoader/MobileTableLoader';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import useStyles from './MobileTable.styles';

const initialDrawerConfig = {
  drawerActionType: 'view',
  open: false,
  row: null,
  rowIndex: 0,
} as const;

const initialActionsDrawerConfig = {
  row: null,
  open: false,
} as const;
interface IPagination {
  page: number;
  perPage: number;
  total: number;
  setPage: (a: number) => void;
  setPerPage: (a: number) => void;
}

interface IMobileTable {
  customHeader?: React.ReactNode;
  isEditable?: boolean;
  isDeletable?: boolean;
  borderedFields?: boolean;
  fieldsLeftPadded?: boolean;
  fieldsAlignment?: 'start' | 'space-between';
  drawerFieldsBordered?: boolean;
  drawerFieldsAlignment?: 'start' | 'space-between';
  rows: any[];
  selected?: any[];
  uniqueRowIdFieldName: string;
  columns: IColumn[];
  actions?: IActions;
  loading?: boolean;
  pagination?: IPagination;
  outerWrapperClassName?: string;
  listingWrapperClassName?: string;
  rowClassName?: string;
  renderPrimaryColumnRightNode?: (
    row: any,
    rows: any[],
    openDrawer: any
  ) => React.ReactNode;
  primaryActions?: IPrimaryAction[];
  customPrimaryAction?: IPrimaryAction;
  renderSecondaryAction?: (
    row: any,
    rows?: any[],
    setDrawerConfig?: any
  ) => React.ReactNode;
  renderDrawerFooter?: (row: any, rows: any[]) => React.ReactNode;
  recordFooterClassName?: string;
  recordFooterVariant: 'primary' | 'secondary';
  getRecordFooterClassName?: (row: any) => string;
  openDetailOnLoadRecordId: any;
  checkboxSelection?: boolean;
  onRowSelected?: (selectedRows: any[]) => void;
  renderDrawerHeaderNode?: (row: any) => React.ReactNode;
  allValuesSelected?: boolean;
}

const MobileTable: React.FC<IMobileTable> = ({
  customHeader,
  rows,
  uniqueRowIdFieldName,
  pagination,
  loading,
  outerWrapperClassName,
  listingWrapperClassName,
  onRowSelected,
  actions,
  columns,
  isEditable,
  isDeletable,
  drawerFieldsBordered,
  drawerFieldsAlignment = 'start',
  renderDrawerFooter,
  customPrimaryAction,
  primaryActions,
  selected: selectedRows,
  renderDrawerHeaderNode,
  allValuesSelected = false,
  ...restProps
}) => {
  const classes = useStyles();

  const [drawerConfig, setDrawerConfig] =
    useState<IDrawerConfig>(initialDrawerConfig);

  const [actionsDrawerConfig, setActionsDrawerConfig] =
    useState<IActionsDrawerConfig>(initialActionsDrawerConfig);

  const onOpenEditDrawer = useCallback(
    (row: any, rowIndex: number) =>
      setDrawerConfig({ drawerActionType: 'edit', open: true, row, rowIndex }),
    [setDrawerConfig]
  );

  const onCloseDrawer = () => setDrawerConfig({ ...drawerConfig, open: false });

  const onDelete = useCallback(() => {
    actions?.onDelete?.(drawerConfig.row);
    onCloseDrawer();
  }, [actions, drawerConfig, onCloseDrawer]);

  // const [popoverAnchorEl, setPopoverAnchorEl] = useState<
  //   null | Element | ((element: Element) => Element)
  // >(null);

  // const onClickPopoverEdit = () => {
  //   onOpenEditDrawer(drawerConfig.row, drawerConfig.rowIndex);
  //   onCloseMoreActionsPopover();
  // };

  // const onOpenMoreActionsPopover = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => setPopoverAnchorEl(event.currentTarget);

  // const onCloseMoreActionsPopover = () => setPopoverAnchorEl(null);

  const isSelected = (row: any) =>
    (selectedRows || []).indexOf(row[uniqueRowIdFieldName]) !== -1;

  const handleRowSelect = useCallback(
    (selectedRow: any) => {
      if (!selectedRows) return;

      const selectedIndex = selectedRows.indexOf(
        selectedRow[uniqueRowIdFieldName]
      );

      let newSelected: any[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          selectedRows,
          selectedRow[uniqueRowIdFieldName]
        );
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      onRowSelected?.(newSelected);
    },
    [selectedRows, onRowSelected]
  );

  if (loading) return <MobileTableLoader />;

  return (
    <>
      <div className={classnames(classes.container, outerWrapperClassName)}>
        {customHeader}

        <div
          className={classnames(classes.mainContent, listingWrapperClassName)}
        >
          {rows?.map((row, index) => (
            <MobileTableRecord
              key={row[uniqueRowIdFieldName]}
              row={row}
              backgroundVariant={index % 2 === 0 ? 'light' : 'dark'}
              rowIndex={index}
              rows={rows}
              onRowSelection={handleRowSelect}
              actions={actions}
              columns={columns}
              isEditable={isEditable}
              isDeletable={isDeletable}
              onOpenEditDrawer={onOpenEditDrawer}
              setDrawerConfig={setDrawerConfig}
              customPrimaryAction={customPrimaryAction}
              primaryActions={primaryActions}
              setActionsDrawerConfig={setActionsDrawerConfig}
              selected={isSelected(row) || allValuesSelected}
              {...restProps}
            />
          ))}
        </div>

        {pagination && <MobilePagination {...pagination} />}
      </div>

      <MobileTableDrawer
        {...drawerConfig}
        actions={actions}
        rows={rows}
        columns={columns}
        onClose={onCloseDrawer}
        isEditable={isEditable}
        isDeletable={isDeletable}
        onOpenEditDrawer={onOpenEditDrawer}
        onDelete={onDelete}
        drawerFieldsBordered={drawerFieldsBordered}
        drawerFieldsAlignment={drawerFieldsAlignment}
        renderDrawerFooter={renderDrawerFooter}
        renderDrawerHeaderNode={renderDrawerHeaderNode}
      />

      <MobilePopover
        open={actionsDrawerConfig.open}
        onClose={() =>
          customPrimaryAction?.onClose?.(
            actionsDrawerConfig.row,
            rows,
            setDrawerConfig,
            setActionsDrawerConfig
          )
        }
      >
        {actionsDrawerConfig.row && (
          <div className={classes.moreActionDrawerWrapper}>
            {primaryActions?.map((currentAction, index) =>
              currentAction.renderCustomAction ? (
                currentAction.renderCustomAction(
                  actionsDrawerConfig.row,
                  rows,
                  setDrawerConfig,
                  setActionsDrawerConfig
                )
              ) : (
                <span
                  role="button"
                  key={index}
                  onClick={() =>
                    currentAction.onClick?.(
                      actionsDrawerConfig.row,
                      rows,
                      setDrawerConfig,
                      setActionsDrawerConfig
                    )
                  }
                  className={classes.moreActionDrawerItem}
                >
                  {currentAction.label}
                </span>
              )
            )}
          </div>
        )}
      </MobilePopover>

      {/* Record 3 dots delete and edit popover
      <Popover
        anchorEl={popoverAnchorEl}
        open={Boolean(popoverAnchorEl)}
        onClose={onCloseMoreActionsPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.moreActionsPaper }}
      >
        {isEditable && (
          <Button
            size="thin"
            color="link"
            variant="text"
            disableTouchRipple={true}
            onClick={onClickPopoverEdit}
            className={classes.moreActionsBtn}
          >
            Edit
          </Button>
        )}
        {isDeletable && (
          <Button
            size="thin"
            color="link"
            variant="text"
            disableTouchRipple={true}
            className={classes.moreActionsBtn}
            onClick={onDelete}
          >
            Delete
          </Button>
        )}
      </Popover>
       */}
    </>
  );
};

export default withGenerateClassName(MobileTable);
