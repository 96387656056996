import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import MuiAppBar, {
  AppBarProps as MuiAppBarProps,
} from '@material-ui/core/AppBar';
import Toolbar, { ToolbarProps } from '@material-ui/core/Toolbar';
import withGenerateClassName from '../../../themes/withGenerateClassName';

type FlexOptions = 'flex-start' | 'center' | 'flex-end';

interface IStyle {
  mainContentAlignment: FlexOptions;
}

const useStyles = makeStyles(
  (theme) => ({
    appBarRoot: {
      backgroundColor: theme?.palette?.basic?.white,
      color: theme?.palette?.basic?.black,
      boxShadow: '0px 1.5px 2px rgba(0, 0, 0, 0.12)',
      height: '52px',
      fontFamily: theme?.typography?.fontFamily,
    },
    toolbarRoot: {
      minHeight: '0',
      height: '52px',
      padding: '0 16px',
      display: 'flex',
      alignItems: 'center',
    },
    mainContent: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: ({ mainContentAlignment }: IStyle) =>
        mainContentAlignment,
    },
    rightChildren: {
      display: 'flex',
      gap: '7.67px',
    },
  }),
  { name: 'ComponentLibrary' }
);

interface IAppbar extends MuiAppBarProps {
  toolbarProps?: ToolbarProps;
  mainContentAlignment: FlexOptions;
  mainContentClassName?: string;
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode | React.ReactNode[];
}

const AppBar: React.FC<IAppbar> = ({
  toolbarProps,
  mainContentAlignment,
  mainContentClassName,
  children,
  leftChildren,
  rightChildren,
  ...restProps
}) => {
  const classes = useStyles({ mainContentAlignment });

  return (
    <MuiAppBar
      classes={{ root: classes.appBarRoot }}
      color="inherit"
      {...restProps}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }} {...toolbarProps}>
        {leftChildren}

        <Box className={[classes.mainContent, mainContentClassName].join(' ')}>
          {children}
        </Box>

        <Box className={classes.rightChildren}>
          {rightChildren && Array.isArray(rightChildren)
            ? rightChildren.map((child) => child)
            : rightChildren}
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default withGenerateClassName(AppBar);
