import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  paddedForMobile?: boolean;
  footerBtnsPosition: 'space-between' | 'flex-start' | 'flex-end' | 'center';
  enableContentOverflow?: boolean;
}

const useStyles = makeStyles(
  (theme: any) => ({
    backDrop: {
      background: 'rgba(14, 13, 12, 0.2)',
    },
    dialogRoot: {},
    dialogPaper: {
      borderRadius: theme.spacing(4),
      background: theme?.palette?.basic?.white,
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    },
    dialogTitleRoot: {
      padding: '0',
    },
    dialogHeader: {
      color: theme?.palette?.secondary?.main,
      padding: ({ paddedForMobile }: StyleProps) =>
        theme.spacing(0, paddedForMobile ? 5 : 6),
      fontSize: '18px',
      height: '59.5px',
      boxSizing: 'border-box',
      fontWeight: 700,
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dialogCrossIconBtn: {
      '&:hover': {
        background: theme?.palette?.basic?.white,
      },
      padding: '0',
    },
    dialogCrossIcon: {
      width: '18.94px',
      height: '18.94px',
    },
    dialogContentRoot: {
      padding: '0',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme?.palette?.basic?.white,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme?.palette?.secondary?.shade_300,
        borderRadius: '5px',
      },
    },
    dialogActionsRoot: {
      padding: '0',
      display: 'block',
    },
    dialogFooter: {
      height: '80px',
      boxSizing: 'border-box',
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      justifyContent: ({ footerBtnsPosition }: StyleProps) =>
        footerBtnsPosition,
      alignItems: 'center',
      gap: '16px',
      padding: ({ paddedForMobile }: StyleProps) =>
        theme.spacing(0, paddedForMobile ? 5 : 6),
    },
    footerBtn: {
      padding: '0 16px',
    },
    secondaryBtn: {
      color: theme?.palette?.secondary?.shade_700,
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
