import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import classnames from 'classnames';
import { Checkbox, Tooltip } from '../../../../atoms';
import { Column, StyleProps } from '../../DesktopTable';

const useStyles = makeStyles(
  (theme: any) => ({
    tableHead: {
      height: '54px',
      backgroundColor: ({ variant }: StyleProps) =>
        variant !== 'striped' ? theme?.palette?.secondary?.shade_50 : '',
      '& th': {
        borderBottom: `2px solid ${theme?.palette?.secondary?.shade_100}`,
      },
    },
    cell: {
      padding: '0 24px',
      fontSize: '14px',
      color: 'black',
      opacity: 0.85,
      border: 'none',
    },
    headCell: {
      fontWeight: 500,
      opacity: 1,
      background: ({ variant }: StyleProps) =>
        variant !== 'striped'
          ? theme?.palette?.secondary?.shade_50
          : theme?.palette?.basic?.white,
    },
    checkboxCell: {
      padding: 0,
      paddingLeft: '16px',
    },
    checkbox: {
      padding: 0,
    },
    noPointerEvent: {
      pointerEvents: 'none',
    },
    sortLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      '& span': {
        width: '100%',
        paddingRight: '8px',
      },
      '&:hover': {
        '& .sortIcon': {
          visibility: 'visible',
        },
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    sortIconRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '10.59px',
      width: '7.18px',
      visibility: 'hidden',
    },
    sortArrowIconRoot: {
      width: 7.18,
      height: 4.58,
      color: '#A09A92',
      '& path': {
        transform: 'scale(5.2)',
      },
    },
    sortActive: {
      color: theme?.palette?.primary?.main,
    },
    avatarCell: {
      paddingLeft: '16px',
    },
    visible: {
      visibility: 'visible',
    },
    border: {
      borderRight: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      borderLeft: `1px solid ${theme?.palette?.secondary?.shade_100}`,
    },
    shadowRight: {
      boxShadow: '0px -3px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    shadowLeft: {
      boxShadow: '0px -3px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    moreIconButtonCell: {
      borderRight: 'none',
      borderLeft: 'none',
    },
    stickyRight: {
      position: 'sticky',
      right: 0,
    },
  }),
  { name: 'ComponentLibrary', index: 2 }
);

const EnhancedTableHead = (props: any) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    checkboxSelection,
    border,
    variant,
    getFixedColumnStyle,
    primaryActions,
    desktopCustomPrimaryAction,
    primaryColumnProps,
    allValuesSelected
  } = props;

  const classes = useStyles({ variant });

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const SortableHeader = ({ column, children }: any) => {
    return (
      <TableSortLabel
        className={classes.sortLabel}
        active={orderBy === column.field}
        onClick={createSortHandler(column.field)}
        IconComponent={() => (
          <SortIcon
            visible={orderBy === column.field}
            classes={classes}
            direction={orderBy === column.field && order}
          />
        )}
      >
        <Tooltip
          title={
            orderBy === column.field
              ? order === 'asc'
                ? 'Click to sort descending'
                : 'Click to cancel sorting'
              : 'Click to sort ascending'
          }
          placement="top"
          color="primary"
          arrow
        >
          <span
            style={{
              width:
                column.width &&
                column.width.indexOf('px') !== -1 &&
                column.width,
            }}
            className={classnames(column.noWrap && classes.noWrap)}
          >
            {children}
          </span>
        </Tooltip>
      </TableSortLabel>
    );
  };

  const SortIcon = ({ direction, classes, visible }: any) => {
    return (
      <div
        className={classnames(
          'sortIcon',
          classes.sortIconRoot,
          visible && classes.visible
        )}
      >
        <ArrowDropUpRoundedIcon
          viewBox="50.5 49 24 24"
          className={classnames(
            classes.sortArrowIconRoot,
            direction === 'asc' && classes.sortActive
          )}
        />
        <ArrowDropDownRoundedIcon
          viewBox="50.5 52 24 24"
          className={classnames(
            classes.sortArrowIconRoot,
            direction === 'desc' && classes.sortActive
          )}
        />
      </div>
    );
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {checkboxSelection && (
          <TableCell
            className={classnames(
              classes.cell,
              classes.checkboxCell,
              classes.headCell
            )}
            padding="checkbox"
            style={{
              ...(columns[0].fixed && {
                position: 'sticky',
                left: 0,
                zIndex: 4,
              }),
            }}
          >
            <Checkbox
              size="large"
              className={classes.checkbox}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={(rowCount > 0 && numSelected === rowCount) || allValuesSelected}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}

        {columns.map((column: Column, columnIndex: number) => {
          if (column.hiddenDesktopColumn) return;

          return column.renderHeader ? (
            <TableCell
              classes={{
                root: classnames(
                  classes.cell,
                  classes.headCell,
                  border && classes.border,
                  columnIndex < columns.length - 1 &&
                    column.fixed === 'left' &&
                    !columns[columnIndex + 1].fixed &&
                    classes.shadowRight,
                  columnIndex > 0 &&
                    !columns[columnIndex - 1].fixed &&
                    columns[columnIndex].fixed === 'right' &&
                    classes.shadowLeft
                ),
              }}
              key={columnIndex}
              align={column.align}
              style={{
                width: column.width,
                borderLeft:
                  columnIndex === 0 && border && '1px solid transparent',
                borderRight:
                  columnIndex === columns.length - 1 &&
                  border &&
                  '1px solid transparent',
                ...getFixedColumnStyle(columnIndex, 'head'),
              }}
            >
              {column.sortable ? (
                <SortableHeader column={column}>
                  {column.renderHeader(column)}
                </SortableHeader>
              ) : (
                column.renderHeader(column)
              )}
            </TableCell>
          ) : (
            <TableCell
              classes={{
                root: classnames(
                  classes.cell,
                  classes.headCell,
                  column.headerClassName,
                  border && classes.border,
                  column.avatar && classes.avatarCell,
                  columnIndex < columns.length - 1 &&
                    column.fixed === 'left' &&
                    !columns[columnIndex + 1].fixed &&
                    classes.shadowRight,
                  columnIndex > 0 &&
                    !columns[columnIndex - 1].fixed &&
                    columns[columnIndex].fixed === 'right' &&
                    classes.shadowLeft
                ),
              }}
              key={columnIndex}
              align={column.align}
              style={{
                width: column.width,
                borderLeft:
                  columnIndex === 0 && border && '1px solid transparent',
                borderRight:
                  columnIndex === columns.length - 1 &&
                  border &&
                  '1px solid transparent',
                ...getFixedColumnStyle(columnIndex, 'head'),
              }}
            >
              {column.sortable ? (
                <SortableHeader column={column}>
                  {column.headerName}
                </SortableHeader>
              ) : (
                <span className={classnames(column.noWrap && classes.noWrap)}>
                  {column.headerName}
                </span>
              )}
            </TableCell>
          );
        })}

        {(primaryActions?.length > 0 || desktopCustomPrimaryAction) && (
          <TableCell
            classes={{
              root: classnames(
                primaryActions?.length > 1 ? classes.cell : classes.checkbox,
                classes.headCell,
                border && classes.border,
                classes.moreIconButtonCell,
                (primaryColumnProps?.sticky ||
                  columns[columns.length - 1]?.fixed) &&
                  classes.stickyRight,
                (primaryColumnProps?.sticky ||
                  columns[columns.length - 1]?.fixed) &&
                  classes.shadowLeft,
                primaryColumnProps?.className,
              ),
            }}
          >
            <div
              className={classes.noWrap}
              style={{ width: primaryColumnProps?.width }}
            >
              {primaryColumnProps?.title}
            </div>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
export default EnhancedTableHead;
