import useStyles from './MobileTableLoader.styles';
import Shimmer from '../../../../atoms/Shimmer/Shimmer';

interface IRecordLoaderProps {
  index: number;
}

const RecordLoader = ({ index }: IRecordLoaderProps) => {
  const classes = useStyles({ isDark: Boolean(index % 2) });
  return (
    <div className={classes.record_wrapper}>
      <div className={classes.record_header}>
        <Shimmer width="32px" height="32px" borderRadius="50%" />
        <Shimmer width="38.22%" height="17px" borderRadius="8px" />
      </div>

      {['100%', '76.28%', '91.64%', '60.91%'].map((width) => (
        <div className={classes.content_row} key={width}>
          <Shimmer width={width} height="17px" borderRadius="8px" />
        </div>
      ))}
      <div className={classes.record_footer}>
        <Shimmer width="26.26%" height="17px" borderRadius="8px" />
        <Shimmer width="26.26%" height="17px" borderRadius="8px" />
      </div>
    </div>
  );
};

const MobileTableLoader = () => (
  <>
    {Array(4)
      .fill(0)
      .map((item, index) => (
        <RecordLoader key={index} index={index} />
      ))}
  </>
);

export default MobileTableLoader;
