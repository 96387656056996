import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '12px',
      padding: '13px 16px',
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '17px',
    },
    headerLeftSection: {
      color: theme?.palette?.secondary?.main,
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      flexGrow: 1,
    },
    crossBtn: {
      padding: '0',
      '& svg': {
        color: theme?.palette?.secondary?.main,
      },
    },
    listRow: {
      padding: '6px 16px',
      fontSize: '14px',
      lineHeight: '17px',
      // TODO: color not in palette
      color: 'rgba(0, 0, 0, 0.85)',
    },
    mainContent: {
      color: theme?.palette?.secondary?.main,
      padding: '16.5px 0',
    },
    borderedMainContent: {
      padding: '2.5px 16px',
      '& $listRow': {
        padding: '10px 0',
        borderTop: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      },
      '& $listRow:first-child': {
        borderTop: 'none',
      },
    },
    spaceBetweenAlignment: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '12px',
      '& .value': {
        textAlign: 'right',
      },
    },
    listRowLabel: {
      fontWeight: 600,
    },
    fullWidth: {
      width: '100%',
    },
    editSelect: {
      height: '40px',
    },
    editWrapper: {
      padding: '6px 16.49px 6px 16px',
      // TODO: color not in palette
      color: 'rgba(0, 0, 0, 0.85)',
    },
    editLabel: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
      marginBottom: '8px',
    },
    footer: {
      borderTop: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '17.5px 16px 18px 16px',
    },
    flexGrow: {
      flexGrow: 1,
    },
  }),
  { name: 'ComponentLibrary' }
);
