import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import classnames from 'classnames';

import useStyles from './MobileTableDrawer.styles';
import MobilePopover from '../../../../atoms/MobilePopover/MobilePopover';
import Button from '../../../../atoms/Button/Button';
import Input from '../../../../atoms/Input/Input';
import Dropdown from '../../../../atoms/Dropdown/Dropdown';
import Avatar from '../../../../atoms/Avatar/Avatar';
interface IAddtionalAction {
  renderAction?: (a: any) => React.ReactNode;
  label?: string;
  onClick?: (a: any) => void;
}

export interface IActions {
  onEdit?: (a: any) => void;
  onDelete?: (a: any) => void;
  additionalAction?: IAddtionalAction;
}

export type TDrawerActionType = 'view' | 'edit';

export interface IDrawerConfig {
  drawerActionType: TDrawerActionType;
  open: boolean;
  row: any;
  rowIndex: number;
}
export interface IColumn {
  headerName: React.ReactNode;
  field: string;
  isEditable?: boolean;
  editType?: 'string' | 'dropdown';
  editDropdownOptions?: any[];
  onEdit?: () => void;
  isCollapsed?: boolean;
  isPrimary?: boolean;
  isUnique?: boolean;
  isSecondary?: boolean;
  isDrawerTitle?: boolean;
  renderColumn?: (a: any, b?: TDrawerActionType) => React.ReactNode;
  valueFormatter?: (a: any) => string;
  columnClassName?: string;
  emptyField?: string | React.ReactNode;
  hidden?: boolean;
}

interface IMobileTableDrawer {
  drawerActionType: TDrawerActionType;
  open: boolean;
  onClose: () => void;
  columns: IColumn[];
  row: any;
  rows: any[];
  rowIndex: number;
  isEditable?: boolean;
  isDeletable?: boolean;
  actions?: IActions;
  onOpenEditDrawer: (row: any, rowIndex: number) => void;
  onDelete: () => void;
  drawerFieldsBordered?: boolean;
  drawerFieldsAlignment?: 'start' | 'space-between';
  renderDrawerFooter?: (row: any, rows: any[]) => React.ReactNode;
  renderDrawerHeaderNode?: (row: any) => React.ReactNode;
}

const MobileTableDrawer: React.FC<IMobileTableDrawer> = ({
  drawerActionType,
  open,
  onClose,
  row,
  rows,
  rowIndex,
  columns,
  isEditable,
  isDeletable,
  actions,
  onOpenEditDrawer,
  onDelete,
  drawerFieldsBordered,
  drawerFieldsAlignment = 'start',
  renderDrawerFooter,
  renderDrawerHeaderNode,
}) => {
  const [data, setData] = useState(row);
  const classes = useStyles();

  let titleColumn: undefined | IColumn;
  const listColumns = [] as IColumn[];
  const editableColumns = [] as IColumn[];

  if (drawerActionType === 'view') {
    columns?.forEach((column) => {
      if (column.isDrawerTitle) titleColumn = column;
      else listColumns.push(column);
    });
  } else {
    columns?.forEach((column) => {
      if (column.isDrawerTitle) titleColumn = column;
      else if (column.isEditable) editableColumns.push(column);
      else listColumns.push(column);
    });
  }

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const onDropdownChange = (name: string, value) =>
    setData({ ...data, [name]: value });

  const onSaveEdits = () => {
    actions?.onEdit?.(data);
    onClose();
  };

  return (
    <MobilePopover open={open} onClose={onClose}>
      {row && (
        <>
          {titleColumn && (
            <div className={classes.header}>
              {titleColumn.renderColumn ? (
                titleColumn.renderColumn(row)
              ) : (
                <div className={classes.headerLeftSection}>
                  <Avatar
                    label={row[titleColumn.field] ?? titleColumn.emptyField}
                    colorIndex={rowIndex}
                  />
                  {titleColumn?.valueFormatter?.(row) ||
                    (row[titleColumn.field] ?? titleColumn.emptyField)}
                </div>
              )}
              {renderDrawerHeaderNode?.(row)}
              <IconButton
                disableRipple
                disableFocusRipple
                component="span"
                onClick={onClose}
                className={classes.crossBtn}
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>
          )}

          <div
            className={classnames(
              classes.mainContent,
              drawerFieldsBordered && classes.borderedMainContent
            )}
          >
            {listColumns.map((column) => {
              const {
                headerName,
                field,
                renderColumn,
                valueFormatter,
                emptyField,
                hidden,
              } = column;
              if (hidden) return null;
              if (renderColumn) {
                return (
                  <div
                    className={classes.listRow}
                    key={`drawer__${field}__${headerName}`}
                  >
                    {renderColumn(row, drawerActionType)}
                  </div>
                );
              }
              return (
                <div
                  className={classnames(
                    classes.listRow,
                    drawerFieldsAlignment === 'space-between' &&
                      classes.spaceBetweenAlignment
                  )}
                  key={field}
                >
                  <span
                    className={classes.listRowLabel}
                  >{headerName}:</span>
                  <span className="value">
                    {valueFormatter
                      ? valueFormatter(row)
                      : row[field] ?? emptyField}
                  </span>
                </div>
              );
            })}
            {drawerActionType === 'edit' &&
              editableColumns.map((column) => {
                const {
                  editType,
                  field,
                  renderColumn,
                  headerName,
                  editDropdownOptions,
                } = column;
                if (renderColumn) return renderColumn(row, drawerActionType);
                if (!editType) return <></>;

                if (editType === 'string') {
                  return (
                    <Input
                      label={`${headerName}:`}
                      name={field}
                      defaultValue={row[field]}
                      placeholder="Enter your details here"
                      onChange={onInputChange}
                      size="small"
                      inputClassName={classes.fullWidth}
                      wrapperClassName={classes.editWrapper}
                      labelClassName={classes.editLabel}
                    />
                  );
                } else if (editType === 'dropdown') {
                  return (
                    <div className={classes.editWrapper}>
                      <div className={classes.editLabel}>{headerName}:</div>
                      <Dropdown
                        value={data[field]}
                        handleChange={(value) => onDropdownChange(field, value)}
                        options={editDropdownOptions}
                        formControlClassName={classes.fullWidth}
                        selectClassName={classes.editSelect}
                      />
                    </div>
                  );
                }
              })}
          </div>

          {renderDrawerFooter?.(row, rows)}

          {(isEditable || isDeletable) &&
            drawerActionType === 'view' &&
            !renderDrawerFooter && (
              <div className={classes.footer}>
                {isDeletable && (
                  <Button
                    size="small"
                    color="danger"
                    fullWidth={!isEditable}
                    onClick={onDelete}
                    buttonWrapperClassName={isEditable ? '' : classes.flexGrow}
                  >
                    Delete
                  </Button>
                )}
                {isEditable && (
                  <Button
                    size="small"
                    color="primary"
                    fullWidth={!isDeletable}
                    onClick={() => onOpenEditDrawer(row, rowIndex)}
                    buttonWrapperClassName={isDeletable ? '' : classes.flexGrow}
                  >
                    Edit
                  </Button>
                )}
              </div>
            )}
          {drawerActionType === 'edit' && !renderDrawerFooter && (
            <div className={classes.footer}>
              <Button
                size="small"
                color="primary"
                fullWidth
                disabled={data === row}
                onClick={onSaveEdits}
                buttonWrapperClassName={classes.flexGrow}
              >
                Save
              </Button>
            </div>
          )}
        </>
      )}
    </MobilePopover>
  );
};

export default MobileTableDrawer;
