import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import useStyles from './DesktopTableLoader.styles';
import Shimmer from '../../../../atoms/Shimmer/Shimmer';

const headerColumns = [
  {
    headerWidth: '13.18%',
    shimmerWidth: '71.42%',
  },
  {
    headerWidth: '14.88%',
    shimmerWidth: '63.25%',
  },
  {
    headerWidth: '11.39%',
    shimmerWidth: '82.67%',
  },
  {
    headerWidth: '13.54%',
    shimmerWidth: '69.53%',
  },
  {
    headerWidth: '13.45%',
    shimmerWidth: '74%',
  },
  {
    headerWidth: '19.10%',
    shimmerWidth: '52.11%',
  },
];

function DesktopTableLoader() {
  const classes = useStyles();

  return (
    <div className={classes.table_wrapper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.table_head}>
          <TableRow>
            {headerColumns.map(({ headerWidth, shimmerWidth }, index) => (
              <TableCell
                style={{ width: headerWidth }}
                key={`${headerWidth}__${shimmerWidth}__${index}`}
              >
                <Shimmer
                  width={shimmerWidth}
                  height="22px"
                  borderRadius="8px"
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.table_body}>
          {Array(4)
            .fill(0)
            .map((k, index) => (
              <TableRow key={index} classes={{ root: classes.table_row }}>
                <TableCell
                  style={{ width: '13.18%' }}
                  classes={{ root: classes.table_cell }}
                  component="th"
                  scope="row"
                >
                  <div className={classes.first_column_content}>
                    <Shimmer width="32px" height="32px" borderRadius="50%" />
                    <Shimmer
                      width="calc(100% - 52px)"
                      height="12px"
                      borderRadius="8px"
                    />
                  </div>
                </TableCell>
                {['14.88%', '11.39%', '13.54%', '13.45%', '19.10%'].map(
                  (width) => (
                    <TableCell
                      classes={{ root: classes.table_cell }}
                      key={width}
                      style={{ width }}
                    >
                      <Shimmer width="100%" height="12px" borderRadius="8px" />
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default DesktopTableLoader;
